/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { Avatar, Typography } from 'antd';
import AvatarCustom from '../AvatarCustom/AvatarCustom';
import ButtonCustom from '../UI/ButtonCustom/ButtonCustom';
import { User } from '../../redux/reducers/user/userInterface';
import { formatDate, truncateString } from '../../Helpers/helperFunctions';
import './projectCard.scss';

export type Props = {
  company: string;
  companyLogo: string;
  description: string;
  endDate: string;
  logo: string;
  name: string;
  people: Array<{
    id: number;
    name: string;
    profilePicture: string;
  }>;
  startDate: string;
};

const ProjectCard: React.FC<Props> = ({
  company,
  companyLogo,
  description,
  endDate,
  logo,
  name,
  people,
  startDate,
}) => {
  const [showFullSummary, setShowFullSummary] = React.useState<boolean>(false);

  const { Title, Text } = Typography;
  const { Group } = Avatar;
  const maxDescriptionLength = 100;
  const summaryIsLong = description.length > maxDescriptionLength;
  const descriptionLimited = truncateString(description, maxDescriptionLength);
  const toggleReadMoreOrLess = () => {
    setShowFullSummary((prevShowFullSummary) => !prevShowFullSummary);
  };

  const getInitialsFromString = (personName: string): string => {
    const words = personName.split(' ');
    let initials = '';

    for (const word of words) {
      if (word.length > 0) {
        initials += word[0].toUpperCase();
      }
    }

    return initials;
  };

  return (
    <div className="projectCard-root">
      <div className="projectCard-top">
        <div className="projectCard-header">
          <AvatarCustom size="md" imageSrc={companyLogo} fallbackText={company[0]} />{' '}
          <div className="projectCard-name-and-client">
            <Title level={4} className="projectCard-name">
              {name}
            </Title>
            <Text className="projectCard-client">{company}</Text>
          </div>
        </div>
        {description && (
          <div className="projectCard-summary">
            <Text className={!showFullSummary ? 'text-ellipsis' : ''}>
              {!showFullSummary ? descriptionLimited : description}
              {summaryIsLong && (
                <ButtonCustom
                  className="projectCard-see-more"
                  onClick={toggleReadMoreOrLess}
                  type="text"
                >
                  {showFullSummary ? 'See Less' : 'See More'}
                </ButtonCustom>
              )}
            </Text>
          </div>
        )}
        {startDate && endDate && (
          <div className="projectCard-dates">
            {formatDate(startDate, 'MMM YYYY')} - {formatDate(endDate, 'MMM YYYY')}
          </div>
        )}
      </div>
      {logo && (
        <div className="projectCard-middle">
          <img className="w-full" src={logo} alt="placeholder" />
        </div>
      )}
      <div className="projectCard-bottom">
        <Text className="inner-subheading">collaborators</Text>
        <div className="projectCard-collaborators">
          <Group
            maxCount={2}
            maxStyle={{
              color: '#112736',
              backgroundColor: '#F3F5F9',
              height: '36px',
              width: '36px',
            }}
            className="projectCard-collaborators-container"
          >
            {people.map((person) => (
              <AvatarCustom
                size="sm"
                imageSrc={person.profilePicture || ''}
                key={person.id}
                fallbackText={getInitialsFromString(person.name)}
              />
            ))}
          </Group>
          {/* <Link className="font-semibold" to="/">
            View Project
          </Link> //TODO: add back when Projects page available */}
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
