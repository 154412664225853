import * as React from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import CardCustom from '../../Components/UI/CardCustom/CardCustom';
import AvatarCustom from '../../Components/AvatarCustom/AvatarCustom';
import { ExtendedUser } from '../../redux/reducers/user/userInterface';
import temporaryPhoto from '../../assets/images/temporary-cover-photo.png'; //TODO: remove when real cover photo available

export type Props = {
  person: ExtendedUser;
};

const PersonBox: React.FC<Props> = ({ person }) => {
  const dateJoinedOn = dayjs(person.joinedOn);

  const meta = {
    avatar: (
      <AvatarCustom
        size="lg"
        imageSrc={person.profilePicture}
        fallbackText={`${person.firstName[0]}${person.lastName[0]}`}
        iconType={dayjs().diff(dateJoinedOn, 'month') <= 6 ? 'new' : undefined}
      ></AvatarCustom>
    ),
    title: `${person.firstName} ${person.lastName}`,
    description: person.jobRole,
  };

  return (
    <li>
      <Link to={`/profile/${person.id}`}>
        <CardCustom
          className="person-box"
          cover={
            <img
              src={person.profileCover || temporaryPhoto}
              alt={`cover of ${person.firstName} ${person.lastName}'s profile`}
              className="person-box-cover"
            />
          }
          meta={meta}
        >
          {dateJoinedOn.format('MMM YYYY')} - Present - Projects: 3
        </CardCustom>
      </Link>
    </li>
  );
};

export default PersonBox;
