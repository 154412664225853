import * as React from 'react';
import ModalCustom from '../UI/ModalCustom/ModalCustom';
import SendTokensSuccessImage from '../../assets/images/send-tokens-success.png';

export type Props = {
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
};
const SendTokensSuccessModal: React.FC<Props> = ({ open, onOk, onCancel }) => {
  return (
    <ModalCustom
      footer="fullLength"
      cancelText="Close"
      okText="Go to Home Page"
      open={open}
      onOk={onOk}
      onCancel={onCancel}
    >
      <div className="small-modal-content">
        <img src={SendTokensSuccessImage} alt="successfully sent tokens" />
        <h2 className="modal-heading">Successfully sent tokens!</h2>
        <p className="modal-subheading send-tokens-modal-subheading">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Consectetur unde error,
          exercitationem quod
        </p>
      </div>
    </ModalCustom>
  );
};

export default SendTokensSuccessModal;
