import * as React from 'react';
import ExperienceCard from '../../Pages/SettingsPage/EditExperienceAndEducationTab/ExperienceCard';
import UserProfileEmptySectionPrompt from '../UserProfileEmptySectionPrompt/UserProfileEmptySectionPrompt';
import DividedSection from '../DividedSection/DividedSection';
import ButtonCustom from '../UI/ButtonCustom/ButtonCustom';
import { WorkExperience } from '../../redux/reducers/user/userInterface';

export type Props = {
  experienceList: Array<WorkExperience>;
};

const cardsShownByDefault = 2;

const UserExperienceSection: React.FC<Props> = ({ experienceList }) => {
  const experienceCards = experienceList.map((item, i) => {
    return <ExperienceCard isEditable={false} experienceItem={item} key={i} />;
  });

  const [isShowingAll, setIsShowingAll] = React.useState(false);
  const [experienceCount, setExperienceCount] = React.useState<number>(cardsShownByDefault);

  const handleShowMore = () => {
    if (experienceCards?.length) {
      if (experienceCount < experienceCards?.length) {
        setExperienceCount(experienceCount + 2);
        setIsShowingAll(false);
      } else {
        setExperienceCount(experienceCards?.length);
        setIsShowingAll(true);
      }
    }
  };
  const handleShowLess = () => {
    setIsShowingAll(false);
    setExperienceCount(cardsShownByDefault);
  };

  return (
    <DividedSection title="Experience">
      {experienceCards && experienceCards.length ? (
        <>
          <div className="experienceSection-cards-grid">
            {isShowingAll ? experienceCards : experienceCards.slice(0, experienceCount)}
          </div>
          {!isShowingAll && experienceCards.length > experienceCount ? (
            <ButtonCustom
              type="default"
              className="w-full block text-center"
              onClick={() => handleShowMore()}
            >
              Show More
            </ButtonCustom>
          ) : (
            <ButtonCustom
              type="default"
              className="w-full block text-center"
              onClick={() => handleShowLess()}
            >
              Show Less
            </ButtonCustom>
          )}
        </>
      ) : (
        <UserProfileEmptySectionPrompt section="any information about your experience" />
      )}
    </DividedSection>
  );
};

export default UserExperienceSection;
