import * as React from 'react';
import EducationCard from '../../Pages/SettingsPage/EditExperienceAndEducationTab/EducationCard';
import UserProfileEmptySectionPrompt from '../UserProfileEmptySectionPrompt/UserProfileEmptySectionPrompt';
import DividedSection from '../DividedSection/DividedSection';
import ButtonCustom from '../UI/ButtonCustom/ButtonCustom';
import { Education } from '../../redux/reducers/user/userInterface';
import './userEducationSection.scss';

export type Props = {
  educationList: Array<Education>;
};
const cardsShownByDefault = 2;

const UserEducationSection: React.FC<Props> = ({ educationList }) => {
  const [isShowingAll, setIsShowingAll] = React.useState(false);
  const [educationCount, setEducationCount] = React.useState<number>(cardsShownByDefault);

  const educationCards = educationList.map((item, i) => (
    <EducationCard isEditable={false} educationItem={item} key={i} />
  ));

  const handleShowMore = () => {
    const educationCardsLength = educationCards.length;
    if (educationCount < educationCardsLength) {
      setEducationCount(educationCount + 2);
      setIsShowingAll(false);
    } else {
      setEducationCount(educationCardsLength);
      setIsShowingAll(true);
    }
  };

  const handleShowLess = () => {
    setIsShowingAll(false);
    setEducationCount(cardsShownByDefault);
  };

  return (
    <DividedSection title="Education">
      {educationCards && educationCards.length ? (
        <>
          {isShowingAll ? educationCards : educationCards.slice(0, educationCount)}
          {!isShowingAll && educationCards.length > educationCount ? (
            <ButtonCustom
              type="default"
              className="w-full block text-center"
              onClick={() => handleShowMore()}
            >
              Show More
            </ButtonCustom>
          ) : (
            <ButtonCustom
              type="default"
              className="w-full block text-center"
              onClick={() => handleShowLess()}
            >
              Show Less
            </ButtonCustom>
          )}
        </>
      ) : (
        <UserProfileEmptySectionPrompt section="any information about your education" />
      )}
    </DividedSection>
  );
};

export default UserEducationSection;
