/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { uid } from 'uid';
import TeamTimeTrackerPersonBox from './TeamTimeTrackerPersonBox';
import TeamTimeTrackerDay from './TeamTimeTrackerDay';
import {
  formatWeekString,
  getAllWeeksInAMonth,
  getWidthOfElementInAGroup,
  getWorkHoursInAMonth,
  getTotalTimeFromTimeEntries,
  formatDate,
} from '../../../Helpers/helperFunctions';
import { GroupedTimeEntry, TeamMember } from '../../../redux/reducers/team/teamInterface';
import { BaseTimeEntry, TimeEntry } from '../../../redux/sharedInterface';

interface BasicProps {
  from: Dayjs;
  singlePerson?: boolean;
}

interface PropsWithData extends BasicProps {
  data: Array<TeamMember<TimeEntry>>;
  groupedData?: never;
}

interface PropsWithGroupedData extends BasicProps {
  data?: never;
  groupedData: Array<TeamMember<GroupedTimeEntry>>;
}

export type Props = PropsWithData | PropsWithGroupedData;

const TeamTimeTrackerMonthly: React.FC<Props> = ({ data, from, singlePerson, groupedData }) => {
  const monthDates = getAllWeeksInAMonth(from);

  const gridTemplateColumnsStyle = {
    gridTemplateColumns: `${singlePerson ? '' : '20% '}repeat(${
      monthDates.length
    }, ${getWidthOfElementInAGroup(monthDates, singlePerson ? 100 : 80)})`,
  };

  const benchmarkHours = getWorkHoursInAMonth(from);

  const dateHeaders = monthDates.map((monthDate) => (
    <div className="teamTimeTracker-calendar-date" key={uid()}>
      {formatWeekString(monthDate.start, monthDate.end)}{' '}
    </div>
  ));

  const getUserElements = (array: Array<TeamMember<BaseTimeEntry>>, isGrouped: boolean) =>
    array.map((user) => {
      const time = monthDates.map((monthDate) => {
        const timeForDate = user.timeEntries.filter((timeEntry: any) => {
          const timeEntryDate = dayjs(isGrouped ? timeEntry.fromDate : timeEntry.date);
          return formatDate(timeEntryDate) === formatDate(monthDate.start);
        });
        return (
          <TeamTimeTrackerDay
            allShown={singlePerson}
            mode="month"
            key={uid()}
            timeEntries={timeForDate}
          />
        );
      });
      const totalTimeForPeriod = getTotalTimeFromTimeEntries(user.timeEntries);
      return (
        <>
          {!singlePerson && (
            <div className="teamTimeTracker-calendar-team-member-details" key={uid()}>
              <TeamTimeTrackerPersonBox
                {...user}
                benchmarkHours={benchmarkHours}
                totalTime={totalTimeForPeriod}
              />
            </div>
          )}
          {time}
        </>
      );
    });

  return (
    <div className="teamTimeTracker-calendar-grid" style={gridTemplateColumnsStyle}>
      {!singlePerson && <div className="teamTimeTracker-calendar-team-member">Team members</div>}{' '}
      {dateHeaders}
      {groupedData ? getUserElements(groupedData, true) : getUserElements(data, false)}
    </div>
  );
};

export default TeamTimeTrackerMonthly;
