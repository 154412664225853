/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';

import { Modal } from 'antd';

import ButtonCustom from '../ButtonCustom/ButtonCustom';
import DividerCustom from '../DividerCustom/DividerCustom';
import FormCustom from '../../FormCustom/FormCustom';

import './modalCustom.scss';

export type Props = {
  open: boolean;
  cancelText?: string;
  children?: React.ReactNode;
  className?: string;
  danger?: boolean;
  form?: boolean;
  formClassName?: string;
  footer?: 'regular' | 'fullLength';
  okButtonDisabled?: boolean;
  okButtonLoading?: boolean;
  cancelButtonDisabled?: boolean;
  okText?: string;
  size?: 'small' | 'medium' | 'regular';
  title?: string;
  withHeader?: boolean;
  onCancel: ([arg]?: any) => void;
  onOk: ([arg]?: any) => void;
  onCancelButton?: () => void; // use if different behaviour is needed on clicking the cancel button and on clicking the mask
};

const ModalCustom: React.FC<Props> = ({
  cancelText,
  className = '',
  children,
  danger,
  form,
  formClassName = '',
  footer,
  okButtonDisabled,
  okButtonLoading,
  cancelButtonDisabled,
  okText = 'OK',
  open,
  size = 'regular',
  title,
  withHeader = false,
  onCancel,
  onCancelButton,
  onOk,
}) => {
  const fullLengthFooter = footer === 'fullLength';

  const cancelButtonId = 'cancel-button';

  const cancelHandler = (e: any) => {
    if (onCancelButton && e.currentTarget.id === cancelButtonId) {
      onCancelButton();
    } else onCancel();
  };

  const primaryButton = (
    <ButtonCustom
      className={fullLengthFooter ? `block text-center ${cancelText ? 'w-1/2' : 'w-full'}` : 'ml-4'}
      type="primary"
      danger={danger}
      htmlType={form ? 'submit' : undefined}
      onClick={form ? undefined : onOk}
      disabled={okButtonDisabled}
      loading={okButtonLoading}
    >
      {okText}
    </ButtonCustom>
  );

  const secondaryButton = cancelText ? (
    <ButtonCustom
      className={fullLengthFooter ? 'block w-1/2 text-center' : ''}
      type="default"
      onClick={onCancelButton}
      disabled={cancelButtonDisabled}
    >
      {cancelText}
    </ButtonCustom>
  ) : null;

  const footerElement =
    footer || form ? (
      <>
        {form ? <DividerCustom /> : null}
        <div className={`modal-footer ${fullLengthFooter ? 'flex w-full' : 'flex justify-end'}`}>
          {secondaryButton}
          {primaryButton}
        </div>
      </>
    ) : null;

  const modalProps = {
    centered: true,
    closable: withHeader,
    footer: form ? null : footerElement,
    cancelButtonProps: onCancelButton ? { id: cancelButtonId } : undefined,
    okButtonProps: { disabled: okButtonDisabled },
    title: withHeader && (title || 'Modal window'),
    width: fullLengthFooter || size === 'small' ? 440 : size === 'medium' ? 540 : 680,
    wrapClassName: `modal-root ${className}`,
    okText,
    open,
    onCancel: cancelHandler,
    onOk,
  };

  return (
    <Modal {...modalProps}>
      <>
        {withHeader && <DividerCustom />}
        {form ? (
          <FormCustom onSubmit={onOk}>
            <div className={`${formClassName} scrollable`}>{children}</div>
            <div className="col-span-full ">{footerElement}</div>
          </FormCustom>
        ) : (
          <div className="scrollable">{children}</div>
        )}
      </>
    </Modal>
  );
};

export default ModalCustom;
